<template>
  <div>

    <!-- Banner -->
    <banner
      :video="bannerVideo"
      height="600"
    />

    <div class="container my-5">

      <!-- Title -->
      <h1 class="mb-5 text-center">
        {{ $t('contact.text') }}
      </h1>

      <b-row>

        <!-- Contacts -->
        <b-col
          lg="6"
          md="12"
        >
          <b-row>

            <b-col>

              <!-- Address -->
              <div
                id="card"
                class="mb-5"
              >
                <b-row>

                  <b-col
                    cols="3"
                    lg="2"
                  >
                    <i
                      class="pe-7s-map pe-5x pe-va"
                      style="color:orange;"
                    />
                  </b-col>

                  <b-col>
                    <h5>{{ $t('contact.visit') }}</h5>
                    <span>
                      {{ direction }}
                    </span>
                  </b-col>

                </b-row>
              </div>

              <!-- Phone Number -->
              <div
                id="card"
                class="mb-5"
              >
                <b-row>

                  <b-col
                    cols="3"
                    lg="2"
                  >
                    <i
                      class="pe-7s-phone pe-5x pe-va"
                      style="color:orange;"
                    />
                  </b-col>

                  <b-col>
                    <h5>{{ $t('contact.call') }}</h5>
                    <span>{{ tel }}</span>
                  </b-col>
                </b-row>
              </div>

              <!-- Email -->
              <div
                id="card"
                class="mb-5"
              >
                <b-row>

                  <b-col
                    cols="3"
                    lg="2"
                  >
                    <i
                      class="pe-7s-mail-open-file pe-5x pe-va"
                      style="color:orange;"
                    />
                  </b-col>

                  <b-col>
                    <h5>{{ $t('contact.write') }}</h5>
                    <a
                      style="color:orange;"
                      @click="mailto()"
                    >
                      <span>{{ email }}</span>
                    </a>
                  </b-col>
                </b-row>
              </div>
            </b-col>

          </b-row>
        </b-col>

        <!-- Google Map-->
        <b-col
          lg="6"
          md="12"
        >
          <GoogleMap class="map-banner-page" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import 'pixeden-stroke-7-icon'
import { BRow, BCol } from 'bootstrap-vue'
import Banner from 'Components/Common/Banner.vue'

import GoogleMap from 'Components/Contact/GoogleMap.vue'

export default {
  name: 'Contact',
  components: {
    Banner,
    GoogleMap,
    BRow,
    BCol,
  },
  data() {
    return {
      direction: 'Polígono de, C. los Gremios Segovianos, 3, Planta Primera, 40195 Hontoria, Segovia (Spain)',
      tel: '+34 644121775',
      email: 'info@limmat-group.com',
      bannerVideo: '/static/limmat-videos/contact.mp4',
    }
  },
  methods: {
    mailto() {
      window.open('mailto:info@limmat-group.com', '_blank')
    },
  },
}
</script>

<style scoped>

  span {
    font-size: 15px;
  }

  h5 {
    font-size: 30px !important;
    color: black !important;
  }

  @media (max-width: 700px){
    h5 {
      font-size: 20px !important;
    }
  }

</style>
