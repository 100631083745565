<template>
  <div>
    <div
      id="map-container"
      class="fullwidth-home-map"
    >
      <gmap-map
        :center="centerLatLng"
        :zoom="15"
        scrollwheel="false"
        style="height: 520px"
      >
        <gmap-marker
          v-for="(marker) in markers"
          :key="marker.title"
          :position="marker.position"
          :icon="marker.icon"
        >
          <gmap-info-window
            :opened="marker.infoWindowStatus"
            @closeclick="marker.infoWindowStatus=false"
          >
            <div class="infoBox">
              <div class="map-box">
                <a class="listing-img-container">
                  <img
                    :src="marker.image"
                    alt=""
                  >
                  <div class="listing-item-content">
                    <h3>{{ marker.title }}</h3>
                    <span><small>{{ marker.address }}</small></span>
                  </div>
                </a>
              </div>
            </div>
          </gmap-info-window>
        </gmap-marker>
      </gmap-map>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      centerLatLng: {
        lat: 40.908597655829354,
        lng: -4.105606718314086,
      },

      markers: [
        {
          position: {
            lat: 40.908597655829354,
            lng: -4.105606718314086,
          },
          title: 'Limmat',
          address: 'Polígono de, C. los Gremios Segovianos, 3, Planta Primera, 40195 Hontoria, Segovia (Spain)',
          image: '/static/images/most-img-1.jpg',
          infoWindowStatus: false,
        },
      ],
    }
  },
}
</script>
